<template>
  <v-form v-model="valid" ref="request_form">
    <v-container>
      <!-- ************waiting dialog -->

      <v-row justify="center">
        <v-dialog v-model="wait_dialog" persistent max-width="500">
          <!-- <template v-slot:activator="{ wait_dialog_show, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="wait_dialog_show">
                Open Dialog
              </v-btn>
            </template> -->
          <v-card>
            <v-card-title class="text-h5">
              {{ wait_dialog_title }}
            </v-card-title>
            <v-card-text>
              <div>
                {{ wait_dialog_body }}
              </div>
              <div>Wait so far in minutes {{ wait_time_min }}</div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="wait_dialog_hide_msg">
                END WAITING (You will be notifed)
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- ************END waiting dialog -->

      <!-- REQUEST FORM -->
      <v-row dense>
        <v-col cols="12">
          <h4>Reviews</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="selected_review"
            :items="sel_rvw_unassigned"
            item-text="text"
            item-value="value"
            label="You may select a review that is unassigned here."
            single-line
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="selected_review != null">
        <v-col cols="12" md="12">

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="request.request_name"
            :rules="requeird_3"
            label="Request Name"
            hint="Your name for this request."
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="request.fromdate"
            :rules="date_rules"
            label="From Date"
            hint="EXMP; '2000-01-01'"
            required
            v-mask="'####-##-##'"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="request.todate"
            label="To Date"
            :rules="date_rules"
            hint="EXMP; '2023-01-01'"
            required
            v-mask="'####-##-##'"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="request.nat_call"
            :items="nat_cal_items"
            attach
            chips
            label="Nature of the Call"
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            class="mt-3"
            v-model="request.zip"
            label="Zip:"
            hint="EXMP; '89502'"
            v-mask="'#####'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            class="mt-3"
            v-model="request.keywords"
            label="Keyword(s):"
            hint="Match All=> EXMP: whip, tire iron "
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row><hr /></v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-btn @click.prevent="send_request" elevation="2" color="secondary">
            Send Request
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-btn @click.prevent="load_request" elevation="2" color="secondary">
            Load Your Request
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="vmodel_one_requestId"
            :items="request_name_items"
            label="Request Names"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <p>Process previously saved results vs current map regions.</p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-btn
            @click.prevent="process_results"
            elevation="2"
            color="secondary"
          >
            Process Results
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="vmodel_one_resultsid"
            :items="results_name_items"
            label="Result Names"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<style></style>

<script>
import { mask } from "vue-the-mask";
import { bus_common } from "../mixins/bus_common";

export default {
  name: "request_form",
  mixins: [bus_common],
  directives: { mask },
  props: ["poly_count"],
  data() {
    return {
      selected_review: "",
      sel_rvw_unassigned: [],
      wait_dialog_body: `The server will acknowledge the reciept of the request and
                notify it if any results were found. If results were found, the
                remote server will begin to geocode the address of each
                incident.`,
      wait_dialog_title: " Waiting for server First Response",
      unassigned_reviews: [],
      results_row_nomap: [],
      vmodel_one_resultsid: 0,
      results_name_items: [],
      wait_time_sec: 0,
      wait_dialog: false,
      wait_for_inprogress_interval: null,
      vmodel_one_requestId: 0,
      valid: false,
      request_name_items: [],
      nat_cal_items: [],
      nat_call_select: [
        {
          "item-text": "ANOY CHILD",
          "item-value": "ANOY CHILD",
        },
        {
          "item-text": "ANOY PHONE",
          "item-value": "ANOY PHONE",
        },
        {
          "item-text": "BURGC",
          "item-value": "BURGC",
        },
        {
          "item-text": "BURGLARY",
          "item-value": "BURGLARY",
        },
        {
          "item-text": "DRUG POSS",
          "item-value": "DRUG POSS",
        },
        {
          "item-text": "DRUGS",
          "item-value": "DRUGS",
        },
        {
          "item-text": "FOLLOW UP",
          "item-value": "FOLLOW UP",
        },
        {
          "item-text": "JUVPROBL",
          "item-value": "JUVPROBL",
        },
        {
          "item-text": "LARC LNATR",
          "item-value": "LARC LNATR",
        },
        {
          "item-text": "LEWD MINOR",
          "item-value": "LEWD MINOR",
        },
        {
          "item-text": "O&R",
          "item-value": "O&R",
        },
        {
          "item-text": "OTHER",
          "item-value": "OTHER",
        },
        {
          "item-text": "RUNAWAY RT",
          "item-value": "RUNAWAY RT",
        },
        {
          "item-text": "SHOOTING",
          "item-value": "SHOOTING",
        },
        {
          "item-text": "STAT SEXA",
          "item-value": "STAT SEXA",
        },
        {
          "item-text": "SUICIDE",
          "item-value": "SUICIDE",
        },
        {
          "item-text": "THEFT - FROM MOTOR VEH",
          "item-value": "THEFT - FROM MOTOR VEH",
        },
        {
          "item-text": "TRF-HITRUN",
          "item-value": "TRF-HITRUN",
        },
        {
          "item-text": "VEH RECSTL - OUTSIDE",
          "item-value": "VEH RECSTL - OUTSIDE",
        },
        {
          "item-text": "WARRANT",
          "item-value": "WARRANT",
        },
        {
          "item-text": "AIMING FA",
          "item-value": "AIMING FA",
        },
        {
          "item-text": "BDW",
          "item-value": "BDW",
        },
        {
          "item-text": "BURG VEH",
          "item-value": "BURG VEH",
        },
        {
          "item-text": "CHILD",
          "item-value": "CHILD",
        },
        {
          "item-text": "DISOR COND",
          "item-value": "DISOR COND",
        },
        {
          "item-text": "DIST PEACE",
          "item-value": "DIST PEACE",
        },
        {
          "item-text": "DOMBATT M",
          "item-value": "DOMBATT M",
        },
        {
          "item-text": "DUI RMC",
          "item-value": "DUI RMC",
        },
        {
          "item-text": "ELDER",
          "item-value": "ELDER",
        },
        {
          "item-text": "EMBEZZ VEH",
          "item-value": "EMBEZZ VEH",
        },
        {
          "item-text": "FALSE IMPR",
          "item-value": "FALSE IMPR",
        },
        {
          "item-text": "FAMILY OFFENSE",
          "item-value": "FAMILY OFFENSE",
        },
        {
          "item-text": "FORGERY",
          "item-value": "FORGERY",
        },
        {
          "item-text": "FRAUD - CREDIT CARD",
          "item-value": "FRAUD - CREDIT CARD",
        },
        {
          "item-text": "FRAUD - FALSE PRETENSES",
          "item-value": "FRAUD - FALSE PRETENSES",
        },
        {
          "item-text": "HUMAN TRAFF-COM SEX ACTS",
          "item-value": "HUMAN TRAFF-COM SEX ACTS",
        },
        {
          "item-text": "ID THEFT",
          "item-value": "ID THEFT",
        },
        {
          "item-text": "LOST PROP",
          "item-value": "LOST PROP",
        },
        {
          "item-text": "MIN POSS",
          "item-value": "MIN POSS",
        },
        {
          "item-text": "MISS ADULT",
          "item-value": "MISS ADULT",
        },
        {
          "item-text": "PSP",
          "item-value": "PSP",
        },
        {
          "item-text": "RAPE",
          "item-value": "RAPE",
        },
        {
          "item-text": "THEFT",
          "item-value": "THEFT",
        },
        {
          "item-text": "WARRANT - TRAF OFF",
          "item-value": "WARRANT - TRAF OFF",
        },
        {
          "item-text": "ANIMAL",
          "item-value": "ANIMAL",
        },
        {
          "item-text": "ATT HOMICI",
          "item-value": "ATT HOMICI",
        },
        {
          "item-text": "ATT ROB SA",
          "item-value": "ATT ROB SA",
        },
        {
          "item-text": "BATT W/SBH",
          "item-value": "BATT W/SBH",
        },
        {
          "item-text": "BATTERY M",
          "item-value": "BATTERY M",
        },
        {
          "item-text": "BURG RES",
          "item-value": "BURG RES",
        },
        {
          "item-text": "BURGR",
          "item-value": "BURGR",
        },
        {
          "item-text": "COUNTERFEI",
          "item-value": "COUNTERFEI",
        },
        {
          "item-text": "COURTESY R",
          "item-value": "COURTESY R",
        },
        {
          "item-text": "DOP RMC",
          "item-value": "DOP RMC",
        },
        {
          "item-text": "FRAUD - IDENTITY THEFT",
          "item-value": "FRAUD - IDENTITY THEFT",
        },
        {
          "item-text": "LOITERING",
          "item-value": "LOITERING",
        },
        {
          "item-text": "MISS PERS",
          "item-value": "MISS PERS",
        },
        {
          "item-text": "NSF",
          "item-value": "NSF",
        },
        {
          "item-text": "OPUFP",
          "item-value": "OPUFP",
        },
        {
          "item-text": "OUTSIDE AG",
          "item-value": "OUTSIDE AG",
        },
        {
          "item-text": "POSS PARA",
          "item-value": "POSS PARA",
        },
        {
          "item-text": "RECKLESS",
          "item-value": "RECKLESS",
        },
        {
          "item-text": "ROBB ARMED",
          "item-value": "ROBB ARMED",
        },
        {
          "item-text": "ROBB STRNG",
          "item-value": "ROBB STRNG",
        },
        {
          "item-text": "SEX OFF - FONDLING",
          "item-value": "SEX OFF - FONDLING",
        },
        {
          "item-text": "SEX OFF - RAPE",
          "item-value": "SEX OFF - RAPE",
        },
        {
          "item-text": "SEX OFF - STATUTORY RAPE",
          "item-value": "SEX OFF - STATUTORY RAPE",
        },
        {
          "item-text": "SUICIDAL",
          "item-value": "SUICIDAL",
        },
        {
          "item-text": "WEAPONS",
          "item-value": "WEAPONS",
        },
        {
          "item-text": "ADW",
          "item-value": "ADW",
        },
        {
          "item-text": "ASSAULT",
          "item-value": "ASSAULT",
        },
        {
          "item-text": "ASSAULT A",
          "item-value": "ASSAULT A",
        },
        {
          "item-text": "ASSAULT-INTIMIDATION",
          "item-value": "ASSAULT-INTIMIDATION",
        },
        {
          "item-text": "ATT SUICID",
          "item-value": "ATT SUICID",
        },
        {
          "item-text": "BATT RMC",
          "item-value": "BATT RMC",
        },
        {
          "item-text": "DOM BATT N",
          "item-value": "DOM BATT N",
        },
        {
          "item-text": "DOM VIOL N",
          "item-value": "DOM VIOL N",
        },
        {
          "item-text": "DOP NRS",
          "item-value": "DOP NRS",
        },
        {
          "item-text": "DRUG EQUIPMENT",
          "item-value": "DRUG EQUIPMENT",
        },
        {
          "item-text": "FOUND PROP",
          "item-value": "FOUND PROP",
        },
        {
          "item-text": "FRAUD",
          "item-value": "FRAUD",
        },
        {
          "item-text": "FRAUD - HACKING/COMP INVA",
          "item-value": "FRAUD - HACKING/COMP INVA",
        },
        {
          "item-text": "GRAND LARC",
          "item-value": "GRAND LARC",
        },
        {
          "item-text": "HIT&RUN AC",
          "item-value": "HIT&RUN AC",
        },
        {
          "item-text": "INDEC EXPO",
          "item-value": "INDEC EXPO",
        },
        {
          "item-text": "LIQUOR",
          "item-value": "LIQUOR",
        },
        {
          "item-text": "MP RETURNE",
          "item-value": "MP RETURNE",
        },
        {
          "item-text": "OIS INVESTIGATION",
          "item-value": "OIS INVESTIGATION",
        },
        {
          "item-text": "OPEN CONT",
          "item-value": "OPEN CONT",
        },
        {
          "item-text": "RUNAWAY",
          "item-value": "RUNAWAY",
        },
        {
          "item-text": "SUSP CIRC",
          "item-value": "SUSP CIRC",
        },
        {
          "item-text": "THEFT - PARTS MOTOR VEH",
          "item-value": "THEFT - PARTS MOTOR VEH",
        },
        {
          "item-text": "TOW",
          "item-value": "TOW",
        },
        {
          "item-text": "TPO/EOP/RO",
          "item-value": "TPO/EOP/RO",
        },
        {
          "item-text": "TRAFFIC",
          "item-value": "TRAFFIC",
        },
        {
          "item-text": "ASSAULT M",
          "item-value": "ASSAULT M",
        },
        {
          "item-text": "ATT BURG C",
          "item-value": "ATT BURG C",
        },
        {
          "item-text": "ATT BURG V",
          "item-value": "ATT BURG V",
        },
        {
          "item-text": "ATT ROB AR",
          "item-value": "ATT ROB AR",
        },
        {
          "item-text": "ATT SEXALT",
          "item-value": "ATT SEXALT",
        },
        {
          "item-text": "BURGV",
          "item-value": "BURGV",
        },
        {
          "item-text": "DOP GRAFFITI",
          "item-value": "DOP GRAFFITI",
        },
        {
          "item-text": "FRAUD - WIRE",
          "item-value": "FRAUD - WIRE",
        },
        {
          "item-text": "FUGITIVE",
          "item-value": "FUGITIVE",
        },
        {
          "item-text": "HOME INVAS",
          "item-value": "HOME INVAS",
        },
        {
          "item-text": "JUV OFFENS",
          "item-value": "JUV OFFENS",
        },
        {
          "item-text": "OMUFP",
          "item-value": "OMUFP",
        },
        {
          "item-text": "PEEPING TOM",
          "item-value": "PEEPING TOM",
        },
        {
          "item-text": "ROBBERY",
          "item-value": "ROBBERY",
        },
        {
          "item-text": "SEAL",
          "item-value": "SEAL",
        },
        {
          "item-text": "THEFT - BUILDING",
          "item-value": "THEFT - BUILDING",
        },
        {
          "item-text": "TRESPASS",
          "item-value": "TRESPASS",
        },
        {
          "item-text": "WARRANT - OUTSIDE",
          "item-value": "WARRANT - OUTSIDE",
        },
        {
          "item-text": "ASSAULT-SIMPLE",
          "item-value": "ASSAULT-SIMPLE",
        },
        {
          "item-text": "BATT NRS",
          "item-value": "BATT NRS",
        },
        {
          "item-text": "BATTERY A",
          "item-value": "BATTERY A",
        },
        {
          "item-text": "CHIL ENDAN",
          "item-value": "CHIL ENDAN",
        },
        {
          "item-text": "CHILD NEGL",
          "item-value": "CHILD NEGL",
        },
        {
          "item-text": "DOM BATT R",
          "item-value": "DOM BATT R",
        },
        {
          "item-text": "DOM VIOL R",
          "item-value": "DOM VIOL R",
        },
        {
          "item-text": "DRUG BUICS",
          "item-value": "DRUG BUICS",
        },
        {
          "item-text": "DRUG SALES",
          "item-value": "DRUG SALES",
        },
        {
          "item-text": "DRUNK",
          "item-value": "DRUNK",
        },
        {
          "item-text": "EMBEZZ RMC",
          "item-value": "EMBEZZ RMC",
        },
        {
          "item-text": "GRAFFITI",
          "item-value": "GRAFFITI",
        },
        {
          "item-text": "HARASSMENT",
          "item-value": "HARASSMENT",
        },
        {
          "item-text": "HOMICIDE - MURDER",
          "item-value": "HOMICIDE - MURDER",
        },
        {
          "item-text": "MURDER",
          "item-value": "MURDER",
        },
        {
          "item-text": "PETIT LARC",
          "item-value": "PETIT LARC",
        },
        {
          "item-text": "PLIMS",
          "item-value": "PLIMS",
        },
        {
          "item-text": "PORNOGRAPHY",
          "item-value": "PORNOGRAPHY",
        },
        {
          "item-text": "STALKING",
          "item-value": "STALKING",
        },
        {
          "item-text": "TAMPER MV",
          "item-value": "TAMPER MV",
        },
        {
          "item-text": "THEFT - SHOPLIFTING",
          "item-value": "THEFT - SHOPLIFTING",
        },
        {
          "item-text": "VICE",
          "item-value": "VICE",
        },
        {
          "item-text": "ACC SHOOT",
          "item-value": "ACC SHOOT",
        },
        {
          "item-text": "ALCOHOL",
          "item-value": "ALCOHOL",
        },
        {
          "item-text": "ARSON",
          "item-value": "ARSON",
        },
        {
          "item-text": "BURG COMM",
          "item-value": "BURG COMM",
        },
        {
          "item-text": "BURG MV",
          "item-value": "BURG MV",
        },
        {
          "item-text": "CHECKS",
          "item-value": "CHECKS",
        },
        {
          "item-text": "CURFEW-LOITER",
          "item-value": "CURFEW-LOITER",
        },
        {
          "item-text": "DEATH",
          "item-value": "DEATH",
        },
        {
          "item-text": "DEFRAUD",
          "item-value": "DEFRAUD",
        },
        {
          "item-text": "DISORDERLY",
          "item-value": "DISORDERLY",
        },
        {
          "item-text": "DOMBATT A",
          "item-value": "DOMBATT A",
        },
        {
          "item-text": "DUI",
          "item-value": "DUI",
        },
        {
          "item-text": "DUI NRS",
          "item-value": "DUI NRS",
        },
        {
          "item-text": "DUTY PAREN",
          "item-value": "DUTY PAREN",
        },
        {
          "item-text": "EF CHG ADD",
          "item-value": "EF CHG ADD",
        },
        {
          "item-text": "EXTORTION",
          "item-value": "EXTORTION",
        },
        {
          "item-text": "HOMICIDE",
          "item-value": "HOMICIDE",
        },
        {
          "item-text": "INCIDENT",
          "item-value": "INCIDENT",
        },
        {
          "item-text": "INCORRIGIB",
          "item-value": "INCORRIGIB",
        },
        {
          "item-text": "KIDNAP",
          "item-value": "KIDNAP",
        },
        {
          "item-text": "LEWD",
          "item-value": "LEWD",
        },
        {
          "item-text": "MISS JUV",
          "item-value": "MISS JUV",
        },
        {
          "item-text": "NARCS",
          "item-value": "NARCS",
        },
        {
          "item-text": "NARCS-DEA",
          "item-value": "NARCS-DEA",
        },
        {
          "item-text": "SEX OFFENS",
          "item-value": "SEX OFFENS",
        },
        {
          "item-text": "THEFT - ALL OTHER",
          "item-value": "THEFT - ALL OTHER",
        },
        {
          "item-text": "THREATS",
          "item-value": "THREATS",
        },
        {
          "item-text": "TRAF OTHR",
          "item-value": "TRAF OTHR",
        },
        {
          "item-text": "TRAFF ACC",
          "item-value": "TRAFF ACC",
        },
        {
          "item-text": "WRNT ARR",
          "item-value": "WRNT ARR",
        },
        {
          "item-text": "ASSAULT-AGGRAVATED",
          "item-value": "ASSAULT-AGGRAVATED",
        },
        {
          "item-text": "ATT BURG R",
          "item-value": "ATT BURG R",
        },
        {
          "item-text": "BATT POLIC",
          "item-value": "BATT POLIC",
        },
        {
          "item-text": "BOMB",
          "item-value": "BOMB",
        },
        {
          "item-text": "CDM",
          "item-value": "CDM",
        },
        {
          "item-text": "CHILD ABUS",
          "item-value": "CHILD ABUS",
        },
        {
          "item-text": "CIVILPROBL",
          "item-value": "CIVILPROBL",
        },
        {
          "item-text": "CPC",
          "item-value": "CPC",
        },
        {
          "item-text": "EMBEZZ NRS",
          "item-value": "EMBEZZ NRS",
        },
        {
          "item-text": "FRAUD CHK",
          "item-value": "FRAUD CHK",
        },
        {
          "item-text": "GTA",
          "item-value": "GTA",
        },
        {
          "item-text": "HARASS",
          "item-value": "HARASS",
        },
        {
          "item-text": "LITTERING",
          "item-value": "LITTERING",
        },
        {
          "item-text": "POSS CS",
          "item-value": "POSS CS",
        },
        {
          "item-text": "PROWLER",
          "item-value": "PROWLER",
        },
        {
          "item-text": "REC STLN",
          "item-value": "REC STLN",
        },
        {
          "item-text": "ROAD RAGE",
          "item-value": "ROAD RAGE",
        },
        {
          "item-text": "SEXASSLT",
          "item-value": "SEXASSLT",
        },
        {
          "item-text": "STLN PROP",
          "item-value": "STLN PROP",
        },
        {
          "item-text": "SXOFFENDER",
          "item-value": "SXOFFENDER",
        },
        {
          "item-text": "UNLAW MV",
          "item-value": "UNLAW MV",
        },
        {
          "item-text": "UTTERING",
          "item-value": "UTTERING",
        },
        {
          "item-text": "VEH RECSTL",
          "item-value": "VEH RECSTL",
        },
        {
          "item-text": "WARANT",
          "item-value": "WARANT",
        },
        {
          "item-text": "DRUG POSS",
          "item-value": "DRUG POSS",
        },
        {
          "item-text": "DRUGS",
          "item-value": "DRUGS",
        },
        {
          "item-text": "ESCAPE",
          "item-value": "ESCAPE",
        },
        {
          "item-text": "FOLLOW UP",
          "item-value": "FOLLOW UP",
        },
        {
          "item-text": "JUVPROBL",
          "item-value": "JUVPROBL",
        },
        {
          "item-text": "LARC LNATR",
          "item-value": "LARC LNATR",
        },
        {
          "item-text": "LEWD MINOR",
          "item-value": "LEWD MINOR",
        },
        {
          "item-text": "O&R",
          "item-value": "O&R",
        },
        {
          "item-text": "OTHER",
          "item-value": "OTHER",
        },
        {
          "item-text": "RUNAWAY RT",
          "item-value": "RUNAWAY RT",
        },
        {
          "item-text": "SHOOTING",
          "item-value": "SHOOTING",
        },
        {
          "item-text": "STAT SEXA",
          "item-value": "STAT SEXA",
        },
        {
          "item-text": "STLNMV OTH",
          "item-value": "STLNMV OTH",
        },
        {
          "item-text": "SUICIDE",
          "item-value": "SUICIDE",
        },
        {
          "item-text": "THEFT - FROM MOTOR VEH",
          "item-value": "THEFT - FROM MOTOR VEH",
        },
        {
          "item-text": "TRF-HITRUN",
          "item-value": "TRF-HITRUN",
        },
        {
          "item-text": "VEH RECSTL - OUTSIDE",
          "item-value": "VEH RECSTL - OUTSIDE",
        },
        {
          "item-text": "WARRANT",
          "item-value": "WARRANT",
        },
        {
          "item-text": "WELFCK",
          "item-value": "WELFCK",
        },
        {
          "item-text": "ANIMAL",
          "item-value": "ANIMAL",
        },
        {
          "item-text": "ASSAULT - SIMPLE",
          "item-value": "ASSAULT - SIMPLE",
        },
        {
          "item-text": "ATT HOMICI",
          "item-value": "ATT HOMICI",
        },
        {
          "item-text": "ATT ROB SA",
          "item-value": "ATT ROB SA",
        },
        {
          "item-text": "BATT W/SBH",
          "item-value": "BATT W/SBH",
        },
        {
          "item-text": "BATTERY M",
          "item-value": "BATTERY M",
        },
        {
          "item-text": "BURG NFORC",
          "item-value": "BURG NFORC",
        },
        {
          "item-text": "BURG RES",
          "item-value": "BURG RES",
        },
        {
          "item-text": "BURGR",
          "item-value": "BURGR",
        },
        {
          "item-text": "COUNTERFEI",
          "item-value": "COUNTERFEI",
        },
        {
          "item-text": "COURTESY R",
          "item-value": "COURTESY R",
        },
        {
          "item-text": "DOP RMC",
          "item-value": "DOP RMC",
        },
        {
          "item-text": "FRAUD - IDENTITY THEFT",
          "item-value": "FRAUD - IDENTITY THEFT",
        },
        {
          "item-text": "LOITERING",
          "item-value": "LOITERING",
        },
        {
          "item-text": "MISS PERS",
          "item-value": "MISS PERS",
        },
        {
          "item-text": "NSF",
          "item-value": "NSF",
        },
        {
          "item-text": "OPUFP",
          "item-value": "OPUFP",
        },
        {
          "item-text": "OUT AST OI",
          "item-value": "OUT AST OI",
        },
        {
          "item-text": "OUTSIDE AG",
          "item-value": "OUTSIDE AG",
        },
        {
          "item-text": "POSS PARA",
          "item-value": "POSS PARA",
        },
        {
          "item-text": "RECKLESS",
          "item-value": "RECKLESS",
        },
        {
          "item-text": "ROBB ARMED",
          "item-value": "ROBB ARMED",
        },
        {
          "item-text": "ROBB STRNG",
          "item-value": "ROBB STRNG",
        },
        {
          "item-text": "SEX OFF - FONDLING",
          "item-value": "SEX OFF - FONDLING",
        },
        {
          "item-text": "SEX OFF - RAPE",
          "item-value": "SEX OFF - RAPE",
        },
        {
          "item-text": "SEX OFF - STATUTORY RAPE",
          "item-value": "SEX OFF - STATUTORY RAPE",
        },
        {
          "item-text": "SUICIDAL",
          "item-value": "SUICIDAL",
        },
        {
          "item-text": "SUPPLEMENTAL REPORT",
          "item-value": "SUPPLEMENTAL REPORT",
        },
        {
          "item-text": "TCF",
          "item-value": "TCF",
        },
        {
          "item-text": "WARRANT-NEW CHARGE",
          "item-value": "WARRANT-NEW CHARGE",
        },
        {
          "item-text": "WEAPONS",
          "item-value": "WEAPONS",
        },
      ],
      request: {
        requestID: 0,
        todate: "",
        fromdate: "",
        nat_call: "",
        request_name: "",
        zip: "",
        keywords: "",
      },
      firstname: "",
      lastname: "",
      date_rules: [(v) => !!v || "Date is required"],
      requeird_rule: [(v) => !!v || "Types is required"],
      requeird_3: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length > 3) || "Name must be greater than 3 characters",
      ],

      nameRulesx: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      email: "",
      emailRulesx: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    wait_time_min() {
      return parseFloat(this.wait_time_sec / 60).toFixed(2);
    },
  },
  watch: {
    request_update_dummy(oldv, newv) {
      this.get_requests();
      this.get_results_names();
    },
  },
  methods: {
    wait_dialog_show() {
      clearInterval(this.wait_for_inprogress_interval);
      this.wait_for_inprogress_interval = null;
      this.wait_dialog = true;
    },
    wait_dialog_hide() {
      this.wait_dialog = false;
    },
    wait_dialog_hide_msg() {
      clearInterval(this.wait_for_inprogress_interval);
      this.wait_for_inprogress_interval = null;
      this.wait_dialog = false;
      this.swall_it(
        "Request Status",
        "You will still be notified of the results of your request when it is completed.",
        "success",
        null,
        null
      );
    },
    process_results() {
      if (this.vmodel_one_resultsid < 1) {
        this.swall_it(
          "Results Name not selected",
          "Please select a Results name from the drop down list.",
          "warning",
          null,
          null
        );
        return;
      }
      this.$emit("process_results", this.vmodel_one_resultsid);
      this.get_requests();
      this.get_results_names();
    },
    load_request() {
      //validate
      if (this.vmodel_one_requestId == 0) {
        this.swall_it(
          "Request Name not selected",
          "Please select a request name from the drop down list.",
          "warning",
          null,
          null
        );
        return;
      }

      let formIn = {};
      formIn.requestID = this.vmodel_one_requestId;
      formIn.useremail = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.apiname = "load_one_request";

      var self = this;
      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          var natcall = data.nat_call_strs.replaceAll("'", "");
          natcall = natcall.split(",");
          self.request = this.mno(data);

          self.requestID = data.requestID;
          self.request.todate = self.$moment(data.todate).format("YYYY-MM-DD");
          self.request.fromdate = self
            .$moment(data.fromdate)
            .format("YYYY-MM-DD");
          self.request.nat_call = natcall;
          self.request.request_name = data.request_name;
        })
        .catch((msg) => {
          this.show_loader(false);
          this.swall_it("Error", msg, "error", null, null);
        });
    },
    get_unassigned_reviews() {
      let formIn = {};
      formIn.useremail = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.apiname = "get_unassigned_reviews";
      var self = this;
      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          self.unassigned_reviews = self.mno(data);
          for (let index = 0; index < self.unassigned_reviews.length; index++) {
            const rvw = self.unassigned_reviews[index];

            self.sel_rvw_unassigned.push({
              text:
                "Name: " +
                rvw.request_name +
                ", ID: " +
                rvw.reviewId_requestID +
                ", User: " +
                rvw.s_user,
              value: rvw.reviewId,
            });
          }
          self.show_loader(false);
        })
        .catch((msg) => {
          this.show_loader(false);
          this.swall_it("Error", msg, "error", null, null);
          this.close_dialog();
        });
    },
    get_requests() {
      let formIn = {};
      formIn.useremail = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.apiname = "get_requests_names";
      var self = this;
      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          if (data.length > 0) {
            self.request_name_items = this.mno(data);
          }
          self.request_name_items.unshift({ text: "None Selected", value: 0 });
        })
        .catch((msg) => {
          this.show_loader(false);
          this.swall_it("Error", msg, "error", null, null);
          this.close_dialog();
        });
    },
    get_results_names(callback) {
      let formIn = {};
      formIn.useremail = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.apiname = "get_results_names";
      var self = this;
      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          if (data.length > 0) {
            self.results_name_items = this.mno(data);
          }
          self.results_name_items.unshift({ text: "None Selected", value: 0 });
          if (callback != null) {
            callback();
          }
        })
        .catch((msg) => {
          this.show_loader(false);
          this.swall_it("Error", msg, "error", null, null);
          this.close_dialog();
        });
    },
    send_request() {
      let valid_form = this.$refs.request_form.validate();
      if (!valid_form) {
        this.swall_it(
          "Send Request Status",
          "Cannot send request. Please fix the fields with red error text.",
          "warning"
        );
        return;
      }
      if (this.request.keywords.length == 0 && this.request.nat_call == "") {
        this.swall_it(
          "Send Request Status",
          "Cannot send request. Please select a 'Nature of the Call' or enter a keyword term.",
          "warning"
        );
        return;
      }
      if (this.poly_count < 1) {
        //no regions are on map
        this.swall_it(
          "Send Request Status",
          "Please draw or load map regions before sending request.",
          "warning"
        );
        return;
      }
      if (this.request.nat_call.length > 0) {
        this.request.nat_call_strs =
          "'" + this.request.nat_call.join("','") + "'";
      } else {
        this.request.nat_call_strs = "";
      }

      let formIn = this.mno(this.request);
      formIn.useremail = this.$store.state.user.email;
      formIn.token = this.$store.state.user.token;
      formIn.apiname = "send_request";
      formIn.nat_call = null;
      var self = this;
      this.$store
        .dispatch("anyapi", formIn)
        .then((data) => {
          //the data is the id of the added request record
          self.request.requestID = data;
          console.log(data);

          self.wait_for_inprogress();
        })
        .catch((msg) => {
          this.show_loader(false);
          this.swall_it("Error", msg, "error", null, null);
          this.close_dialog();
        });
    },
    wait_for_inprogress() {
      var self = this;
      let int_time = 10000;
      this.wait_dialog_show();
      this.wait_for_inprogress_interval = setInterval(() => {
        let formIn = {
          requestID: this.request.requestID,
          apiname: "check_request_inprocess",
        };

        this.$store
          .dispatch("anyapi", formIn)
          .then((data) => {
            console.log(data);

            if (data.msg != "not yet") {
              clearInterval(this.wait_for_inprogress_interval);
              self.wait_dialog_hide_msg();
              self.swall_it(
                "Send Request Status",
                data.msg,
                "success",
                function () {
                  self.wait_for_data_ready();
                },
                null,
                7000
              );
            } else {
              self.wait_time_sec += 5;
            }
          })
          .catch((msg) => {
            this.show_loader(false);
            this.swall_it("Error", msg, "error", null, null);
          });
      }, int_time);
    },
    wait_for_data_ready() {
      var self = this;
      let int_time = 10000;
      self.wait_time_sec = 0;
      this.wait_dialog_title = "Waiting for Server Data Ready";
      this.wait_dialog_body =
        "The remote server will notitfy you that the data has been made ready to transfer over an encrypted system to the local server.";
      this.wait_dialog_show();
      this.wait_for_inprogress_interval = setInterval(() => {
        let formIn = {
          requestID: this.request.requestID,
          apiname: "is_data_ready",
        };

        this.$store
          .dispatch("anyapi", formIn)
          .then((data) => {
            console.log(data);

            if (data.msg != "not yet") {
              clearInterval(this.wait_for_inprogress_interval);
              self.wait_dialog_hide_msg();
              self.swall_it(
                "Send Request Status",
                "The data will now be filter by the map region(s).",
                "success",
                function () {
                  self.$emit("process_results", self.request.requestID);
                },
                null,
                7000
              );
            } else {
              self.wait_time_sec += 5;
            }
          })
          .catch((msg) => {
            this.show_loader(false);
            this.swall_it("Error", msg, "error", null, null);
            this.close_dialog();
          });
      }, int_time);
    },
    compareObjects(object1, object2, key) {
      const obj1 = object1[key].toUpperCase();
      const obj2 = object2[key].toUpperCase();

      if (obj1 < obj2) {
        return -1;
      }
      if (obj1 > obj2) {
        return 1;
      }
      return 0;
    },
    init() {
      this.show_loader(true);

      let self = this;
      this.get_unassigned_reviews();
      // for (let index = 0; index < this.nat_call_select.length; index++) {
      //   const nat = this.nat_call_select[index]["item-text"];

      //   this.nat_cal_items.push(nat);
      // }
      // this.nat_cal_items.sort();
      // this.nat_cal_items = this.nat_cal_items.sort((a, b) =>
      //   a["item-text"] > b["item-text"] ? 1 : -1
      // );
      // this.nat_cal_items.sort((a, b) => {
      //   return this.compareObjects(a, b, "item-text");
      // });
      // this.get_requests();
      // this.get_results_names(function () {
      //   self.show_loader(false);
      // });
    },
  },

  mounted() {
    this.init();
  },
};
</script>
